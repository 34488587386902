Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.postMethod='POST';
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'bx_block_advanced_search/search/search'
exports.getAllSearchApiEndPoint = 'bx_block_advanced_search/search/search_business_account'
exports.getNearByLocation = 'bx_block_advanced_search/search/search_nearby_bars'
exports.getFiltersEndPoint = 'bx_block_advanced_search/search'
exports.getViewedProfileInfo = "bx_block_advanced_search/search/create_view"
exports.getCategorySubcategory = "bx_block_categories/sub_categories/find_sub_categories";
exports.errorTitle = 'Error'
exports.businessLikeEndPoint = "bx_block_like/likes"
// Customizable Area End
