import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    CardMedia,
    CardContent,
    Grid,
    CircularProgress, Backdrop
} from "@material-ui/core";
import Header from "./Header.web";
import NavigationMenu from "./NavigationMenu.web";
import "./NavigationMenu.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
    typography: {
      "fontFamily": `"Museo Sans", sans-serif`,
     }
 });
// Customizable Area End

import AboutUsController, {
    Props,
} from "./AboutUsController";

export default class AboutUs extends AboutUsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
            <Box style={{ overflowX: "hidden" }}>
                <Header {...this.props} />
                <Box  sx={webStyle.mainWrapperBackground} style={{transition: 'background-image 0.3s ease-in-out', backgroundImage: `url('${this.state.aboutUsData?.banner?.url}')`}} >
                    <Box className="about-heading-container">
                        <Typography className="about-heading" gutterBottom>{this.state.aboutUsData.title}</Typography>
                        <Typography className="heading-description">{this.state.aboutUsData.sub_title}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Backdrop
                        open={this.state.loading}
                        style={{ color: '#fff', zIndex: 1000 }}
                    >
                        <CircularProgress color="inherit" size={30} />
                    </Backdrop>
                </Box>
                <Box className="about-img-des-container">
                    <Grid container className="img-container">
                        {this.state.aboutUsData?.team_members?.map((member: any) => (
                            <Grid item md={3} xs={3} className="item-img-container" key={member.id}>
                                <CardMedia
                                    component="img"
                                    alt={member.name}
                                    image={member.avatar.url}
                                    className="aboutus-card-img"
                                />
                                <CardContent className="card-content-container">
                                    <Typography gutterBottom className="typo-heading-aboutus">
                                        {member.name}
                                    </Typography>
                                    <Typography className="typo-body-aboutus">
                                        {member.position}
                                    </Typography>
                                </CardContent>
                            </Grid>
                        ))}
                    </Grid>
                    <Box className="about-description">
                        <div className="about-description2" dangerouslySetInnerHTML={{ __html: this.state.aboutUsData?.description }} />
                    </Box>
                </Box>
                <NavigationMenu {...this.props} />
            </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    mainWrapperBackground: {
        backgroundRepeat: "no-repeat",
        backgroundSize : "cover",
        marginTop:"5.625vw",
    },
}
// Customizable Area End
