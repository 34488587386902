export const starIcon = require("../assets/image_star.png");
export const uploadPhoto = require("../assets/upload-photo.png");
export const searchIcon = require("../assets/search_icon.svg");
export const addIcon = require("../assets/add_icon.svg");
export const arrowDownIcon = require("../assets/image_arrow_drop_down.jpg");
export const groupImg = require("../assets/group_popover.jpg");
export const baileyImg = require("../assets/image_bailey-road-tavern.jpg");
export const barImg = require("../assets/bar_image.svg")
export const barIcon = require("../assets/bar_icon.svg");
export const wineryIcon = require("../assets/Winery_icon.svg");
export const restaurantIcon = require("../assets/Restaurant_icon.svg");
export const breweryIcon = require("../assets/Brewery_icon.svg");
export const likeStar = require("../assets/like.svg");
export const unLikeStar = require("../assets/unlike.svg");