Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.postMethod='POST';
exports.businessDataAPIEndpoint ="bx_block_advanced_search/search/business_data";
exports.moreInfoDataEndPoint="bx_block_advanced_search/search/business_more_info";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.businessLikeEndPoint = "bx_block_like/likes";
exports.reportAccountEndPoint= "bx_block_advanced_search/reports";
exports.getShareUrlEndPoint="bx_block_catalogue/catalogues/share_catologue_link";
// Customizable Area End