export const backgroundImg = require("../assets/image_sign-up-background.svg");
export const barIcon = require("../assets/bar_icon.svg");
export const wineryIcon = require("../assets/Winery_icon.svg");
export const restaurantIcon = require("../assets/Restaurant_icon.svg");
export const breweryIconUnselected = require("../assets/Brewery_icon_unselected.svg");
export const barIconUnselected = require("../assets/bar_icon_unselected.svg");
export const wineryIconUnselected = require("../assets/Winery_icon_unselected.svg");
export const restaurantIconUnselected = require("../assets/Restaurant_icon_unselected.svg");
export const breweryIcon = require("../assets/Brewery_icon.svg");
export const badge1 = require("../assets/badge1.png");
export const badge2 = require("../assets/badge2.png");
export const badge3 = require("../assets/badge3.png");
export const friendsImg = require("../assets/friends_img.png");
export const userImg = require("../assets/user_img.png");
export const eventPicImg = require("../assets/event_pic_img.png");
export const businessLogo = require("../assets/BusinessLogo.jpg");
export const barChart = require("../assets/bar_chart.png");
export const reviewStartIcon =  require("../assets/view.svg");
export const leftArrow =  require("../assets/button_icon_left.svg");
export const RightArrow =  require("../assets/button_icon_right.svg");
export const CloseBtn =  require("../assets/button_close_white.svg");
export const UplaodImgBtn =  require("../assets/upload_img_icon.svg");
export const btnCancel = require("../assets/image_close.svg");