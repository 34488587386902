import React from "react";
// Customizable Area Start
import { Box, Button, Divider, Grid, Paper, Tab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Select, MenuItem, FormControl, Snackbar, Alert} from "@mui/material";
import { Typography, Backdrop, CircularProgress } from "@material-ui/core";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../navigationmenu/src/Header.web";
import Share from "../../share/src/Share.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
import Scheduling from "../../scheduling/src/Scheduling.web";
import {
  activityAvatar,
  arrowLeft,
  arrowRight,
  badge1,
  badge2,
  badge3,
  badge4,
  badge5,
  imgReport,
  imgShare,
  imgStar,
  likeStar,
  btnCancel,
  menuSs
} from "./assets";
import "./Catalogue.css";
const theme2 = createTheme({
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
   }
});
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentIndex, eventsPerPage, businessEvents } = this.state;
    const displayedEvents = businessEvents.slice(
      currentIndex,
      currentIndex + eventsPerPage
    );
    const placeholders = Array.from({ length: 10 - this.state.imageLibrary?.length });

    // Customizable Area End
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
        <ThemeProvider theme={theme2}>
          <Box style={{display:'flex', flexDirection:'column', overflowX:'hidden'}}>
          <Header {...this.props} />
          <Box>
            <Backdrop
              open={this.state.isLoading}
              style={{ color: '#fff', zIndex: 1000 }}
            >
              <CircularProgress color="inherit" size={30} />
            </Backdrop>
          </Box>
            <Box className="sub-directory-img">
              <img
                className="sub-directory-img"
                src={this.state.businessBackground}
                alt="sub-directory-img"
              />
            </Box>
            <Box className="business-logo-info-container">
              <Box>
                <img
                  className="business-logo"
                  src={this.state.businessLogo}
                  alt="logo"
                />
              </Box>
              <Box>
                <Typography className="business-container-name">
                 {this.state.businessDetails.business_name}
                </Typography>
                <Typography className="business-container-category">
                  {this.state.helpUsHelpYouDetails.catgory_name}
                </Typography>
                <Box className="sub-category-container">
                  <Typography className="business-container-type">
                    {this.state.subCategoryName.join('/')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
            <Box className="star-share-report-container">
                <Box className="star-share-container">
                  <Box>
                    <Button className="btn-subdirectory" onClick={this.state.loginToken ? this.handleLikeChange : this.pleaseLoginPopup}>
                      <img className="share-btn-icon-catalogue" src={this.state.businessLiked ? likeStar : imgStar} />
                      <Typography className="btn-share-moreinfo-container">
                        Like
                      </Typography>
                    </Button>
                    <Snackbar open={this.state.loginSnackbar} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                      <Alert
                        severity="error"
                        variant="filled"
                        className="alert-submission-login"
                        sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px' }}
                      >
                        Please Login!
                      </Alert>
                    </Snackbar>
                  </Box>
                  <Box>
                    <div>
                      <Button className="btn-subdirectory" data-test-id="handleClickBtn"  onClick={this.handleClickOpenSharePopup}>
                        <img className="share-btn-icon-catalogue" src={imgShare} />
                        <Typography className="btn-share-moreinfo-container" >
                          Share
                        </Typography>
                      </Button>
                      <Dialog className="pop-up-dialog-catalogue" open={this.state.setOpenShare} onClose={this.handleClose}>
                        <DialogActions className="pop-up-dialog-action-catalogue">
                          <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn2" onClick={this.handleClose} color="primary">
                            <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                          </Button>
                        </DialogActions>
                        <Share {...this.props} shareUrl={this.state.shareUrl}/>
                      </Dialog>
                    </div>
                  </Box>
                </Box>
                <Box>
                  <Button className="btn-subdirectory" data-test-id="handleClickBtnReport" onClick={this.state.loginToken ? this.handleClickOpenReport : this.pleaseLoginPopup}>
                    <img className="share-btn-icon-catalogue" src={imgReport} />
                    <Typography className="btn-share-moreinfo-container">
                      Report
                    </Typography>
                  </Button>
                  <Dialog className="pop3-up-dialog-catalogue" open={this.state.setOpenReport} onClose={this.handleClose}>
                        <DialogActions className="pop-up-dialog-action-catalogue">
                          <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn3" onClick={this.handleClose} color="primary">
                        <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                      </Button>
                    </DialogActions>
                    <DialogTitle className="pop-up2-dialog-title-catalogue">Flag this account</DialogTitle>
                    <DialogContent className="pop-up3-dialog-content-catalogue" >
                      <DialogContentText className="pop-up3-dialog-content-text-catalogue" >
                        <FormControl className="from-control-catalogue" >
                          <Select
                            sx={{
                              '.MuiOutlinedInput-notchedOutline': {
                                borderColor: "#712220",
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#712220",
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#712220",
                              },
                            }}
                            value={this.state.flagReason}
                            data-test-id="flagReason"
                            className="flag-dropdown"
                            inputProps={{ 'aria-label': 'Without label' }}
                            displayEmpty
                            disableUnderline
                            onChange={(event) => this.handleFlagReasonChange(event.target.value)}
                          >
                            <MenuItem value="" style={{ display: "none" }}>
                              Select reason
                            </MenuItem>
                            <MenuItem value="Incorrect Information" className="menuitem-list-catalogue" >Incorrect Information</MenuItem> <br></br>
                            <MenuItem value="Crude/ Rude/ Offensive" className="menuitem-list-catalogue" >Crude/ Rude/ Offensive</MenuItem><br></br>
                            <MenuItem value="Stolen Account" className="menuitem-list-catalogue" >Stolen Account</MenuItem><br></br>
                            <MenuItem value="Other" className="menuitem-list-catalogue" >Other</MenuItem><br></br>
                          </Select>
                        </FormControl>
                      </DialogContentText>
                      <DialogContentText className="pop-up3-dialog-content-text-catalogue" >
                        <Box >
                          <TextField
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "#712220",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#712220",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#712220",
                                },
                                "& .MuiInputBase-input": {
                                  fontSize: "1.111vw",
                                  fontFamily: 'Museo Sans, sans-serif',
                                },
                              },
                            }}
                            fullWidth
                            data-test-id="textAreaLabel"
                            multiline
                            rows={10}
                            variant="outlined"
                            className="text-area-style-catalogue-main"
                            placeholder="Let us know so we can work to fix the issue..."
                            onChange={(event) => this.handleDescriptionChangeDirectory(event.target.value)}
                          />
                        </Box>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions className="pop-up3-dialog-action-catalogue">
                      <Button type="submit" className="btnSubmitCatalogue" disabled={!(this.state.flagReason && this.state.flagDescription)} onClick={this.reportBusinessAccount}>Submit</Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar open={this.state.successSnackbarFlag} autoHideDuration={3000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                    <Alert
                      severity="success"
                      variant="filled"
                      className="alert-submission"
                      sx={{ width: '100%', height: '50%', padding: '0px 12px 0px 12px', alignItems:'center' }}
                    >
                      Account Reported Successfully!
                    </Alert>
                  </Snackbar>
                </Box>
              </Box>
              <Box className="box1-box2-container">
                <Box className="box-1-container">
                  <Box className="box1-description-container">
                    <Typography className="sub-directory-heading">
                      Description
                    </Typography>
                    <Typography className="sub-directory-heading-description">
                     {this.state.helpUsHelpYouDetails.description}
                    </Typography>
                  </Box>
                  <div>
                  <Box>
                    <Box className="subdirectory-heading-container-1">
                      <Typography className="sub-directory-heading">
                        Amenities
                      </Typography>
                      <Typography className="sub-directory-heading-info" data-test-id="handleClickBtnMore" onClick={this.handleClickOpen}>
                        More info
                      </Typography>
                      </Box>
                      <Box className="amenities-img-container">
                        {this.state.important_feature
                          .filter((feature) => feature.active)
                          .map((feature) => (
                            <Box key={feature.name} className="icon-container-amenities" >
                              <img
                                src={feature.icon.url}
                                alt={feature.name}
                                className="icon-img-catalogue"
                              />
                              <span className="fitericon-span-catalogue">{feature.name}</span>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  <Dialog className="pop-up-dialog-amenities-catalogue" open={this.state.setOpen} onClose={this.handleClose}>
                      <DialogActions className="pop-up-dialog-action-catalogue">
                        <Button className="pop-up-btn-catalogue"  data-test-id="handleCloseBtn1" onClick={this.handleClose} color="primary">
                          <img className="cancel-popup-catalogue" src={btnCancel} alt="image-close"></img>
                        </Button>
                      </DialogActions>
                      <DialogContent className="pop-up-dialog-content-catalogue" >
                        <DialogContentText className="pop-up-dialog-content-text-catalogue">
                            <Box style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' , width:"40.972vw"}}>
                              {this.state.more_info && this.state.more_info.map((feature, index) => (
                                <div key={index} style={{ display:"flex" , gap:"4px"}}>
                                  <Typography className="amenities-more-typo">{feature.name}:</Typography>
                                  <Typography className="amenities-more-typo">
                                    {Array.isArray(feature.value)
                                      ? feature.value.join(', ')
                                      : feature.value.toString()}
                                  </Typography>
                                </div>
                              ))}
                            </Box>
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <Divider className="divider-sub" />
                  <Box>
                    <Box className="subdirectory-heading-container-1">
                      <Typography className="sub-directory-heading">
                        Image Library
                      </Typography>
                      <Typography className="sub-directory-heading-info">
                        See all
                      </Typography>
                    </Box>
                    <Box className="itemGridBoxSubDirectory" sx={{ flexGrow: 1 }}>
                      <Grid container spacing={4} columns={10}>
                        {this.state.imageLibrary && this.state.imageLibrary.map((image:any, index:number) => (
                          <Grid item xs={2} key={index}>
                            <Paper className="libraryImageContainerSubDirectory1">
                              <img className="img-library-subdirectory" src={image.url} alt={image.filename} />
                            </Paper>
                          </Grid>
                        ))}
                        {placeholders.map((_, index) => (
                          <Grid item xs={2} key={index + this.state.imageLibrary.length}>
                            <Paper className="libraryImageContainerSubDirectory"></Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>
                  <Divider className="divider-sub" />
                  <Box>
                    <Box className="subdirectory-heading-container-1">
                      <Typography className="sub-directory-heading">
                        Badges
                      </Typography>
                      <Typography className="sub-directory-heading-info">
                        See all
                      </Typography>
                    </Box>
                    <Box className="badges-img-container">
                      <img className="badges-img-style" src={badge1} />
                      <img className="badges-img-style" src={badge2} />
                      <img className="badges-img-style" src={badge3} />
                      <img className="badges-img-style" src={badge4} />
                      <img className="badges-img-style" src={badge5} />
                    </Box>
                  </Box>
                </Box>
                <Box className="box-2-container">
                  <Box>
                    <Typography noWrap className="box2-heading" gutterBottom>
                      {this.state.businessDetails.business_contact_no}
                    </Typography>
                    <Typography className="box2-heading" gutterBottom>
                      {this.state.businessDetails.website}
                    </Typography>
                    <Typography className="box2-heading" gutterBottom>
                      {this.state.businessDetails.address}
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', typography: 'body1', marginTop: "32px" }}>
                    <TabContext value={this.state.hoursValue}>
                      <Box sx={{ borderColor: 'divider' }}>
                        <TabList onChange={this.handleHourChange} data-test-id="hoursChange" className="tablist-catalogue" aria-label="lab API tabs example" TabIndicatorProps={{
                          style: {
                            backgroundColor: "transparent"
                          }
                        }} >
                          <Tab className={this.state.hoursValue === "1" ? "selected-tab-happy-hours hours-view-tab" : "happy-hours-tab hours-view-tab"} label="Hours" value="1" />
                          <Tab className={this.state.hoursValue === "2" ? "selected-tab-happy-hours hours-view-tab" : "happy-hours-tab hours-view-tab"} label="Happy Hours" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel className="tab-panel-catalogue" value="1">
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Mon:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Monday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Tue:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Tuesday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Wed:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Wednesday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Thu:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Thursday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Fri:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Friday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Sat:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Saturday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Sun:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours1.Sunday}
                          </Typography>
                        </Box>
                      </TabPanel>
                      <TabPanel className="tab-panel-catalogue" value="2">
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Mon:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Monday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Tue:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Tuesday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Wed:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Wednesday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Thu:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Thursday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Fri:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Friday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Sat:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Saturday}
                          </Typography>
                        </Box>
                        <Box className="day-time-typo">
                          <Typography gutterBottom className="schedule-item">
                            Sun:
                          </Typography>
                          <Typography gutterBottom className="schedule-item">
                            {this.state.happyHours2.Sunday}
                          </Typography>
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Box>
                  <Box className="menu-wrapper-catalogue">
                    <Box className="subdirectory-heading-container-2">
                      <Typography className="sub-directory-heading">
                        Menu
                      </Typography>
                    </Box>
                    <Box className="menu-img-btn-wrapper">
                      <img className="menu-img-catalogue" src={menuSs}></img>
                      <Button className="btnStyleMenuPdf">View Menu</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="subdirectory-activity-container">
                <Divider className="divider-sub" />
                <Box className="subdirectory-heading-container-2">
                  <Typography className="sub-directory-heading">
                    Activity
                  </Typography>
                  <Typography className="sub-directory-heading-info">
                    See all
                  </Typography>
                </Box>
                <Box className="itemGridBoxActivity" sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    {Array(3)
                      .fill(null)
                      .map((_, index) => (
                        <Grid item xs={4} key={index}>
                          <Paper className="eventActivityContainer">
                            <img
                              className="recent-img"
                              src={activityAvatar}
                              alt={`Friend ${index + 1}`}
                            />
                            <Box style={{ marginLeft: "1.111vw" }}>
                              <Typography
                                noWrap
                                className="event-acitivity-name"
                              >
                                Matt Milan
                              </Typography>
                              <Typography
                                gutterBottom
                                noWrap
                                className="event-acitivity-type"
                              >
                                Earned the Brew Mister Badge
                              </Typography>
                              <Typography
                                noWrap
                                className="event-acitivity-date"
                              >
                                03/15/24
                              </Typography>
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
                <Divider className="divider-sub" />
                <Box className="subdirectory-heading-container-2">
                  <Typography className="sub-directory-heading">
                    Upcoming Events/Specials
                  </Typography>
                  <Typography className="sub-directory-heading-info" onClick={this.handleEventOpen}>
                    See all
                  </Typography>
                </Box>
              <Dialog className="pop-up-dialog-event-catalogue" open={this.state.setEventOpen} onClose={this.handleClose}>
                <DialogActions className="pop-up-dialog-action-catalogue">
                  <Button className="pop-up-btn-catalogue" data-test-id="handleCloseBtn4" onClick={this.handleClose} color="primary">
                    <img className="cancel-popup-catalogue-event" src={btnCancel} alt="image-close"></img>
                  </Button>
                </DialogActions>
                <DialogContent className="pop-up-dialog-content-event">
                  <Scheduling {...this.props} id={this.state.business_Id} canAddEvents={false}/>
                </DialogContent>
              </Dialog>
              </Box>
              <Box className="upcoming-events-container">
                <Button
                  data-test-id="prevBtn"
                  onClick={this.handlePrevClick}
                  disabled={currentIndex === 0}
                >
                  <img className="upcoming-arrow-btn" src={arrowLeft} />
                </Button>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={1}>
                    <Grid container item spacing={3}>
                      {displayedEvents.map((event, index) => (
                        <Grid item xs={3} key={index}>
                          <Paper className="upcomingEventContainer">
                            <div className="event-day">{event.attributes.event_day}</div>
                            <div className="event-date">{event.attributes.date_of_booking}</div>
                            <Typography className="event-time" gutterBottom> {event.attributes.start_time} </Typography>
                            <div className="event-name">{event.attributes.event_type}</div>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
                <Button
                  data-test-id="nextBtn"
                  onClick={this.handleNextClick}
                  disabled={
                    currentIndex >= businessEvents.length - eventsPerPage
                  }
                >
                  <img className="upcoming-arrow-btn" src={arrowRight} />
                </Button>
              </Box>
            </Box>
            <NavigationMenu {...this.props} />
          </Box>
        </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    //marginBottom: 15,
    height: 150,
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
};
// Customizable Area End
