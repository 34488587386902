import React from "react";

// Customizable Area Start
import { Container, Box, Typography , DialogTitle, DialogContent, DialogContentText} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import { FacebookShareButton , TwitterShareButton, EmailShareButton} from 'react-share';
import { fbIcon, mailIcon, x1Icon, copyIcon} from './assets';
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={shareTheme}>
        {this.state.linkCopied && <div className="pop-up2-dialog-share-catalogue">Link Copied!</div>}
        <DialogTitle className="pop-up2-dialog-title-catalogue">Share this page</DialogTitle>
          <DialogContent className="pop-up2-dialog-content-catalogue" >
            <DialogContentText className="pop-up2-dialog-content-text-catalogue" >
              <FacebookShareButton url={this.props.shareUrl}>
                <img className="share-popup-icon" src={fbIcon}></img>
              </FacebookShareButton>
              <TwitterShareButton url={this.props.shareUrl}>
                <img className="share-popup-icon" src={x1Icon}></img>
              </TwitterShareButton>
              <img className="share-popup-icon" src={copyIcon} data-test-id="facebook-share-button" onClick={this.handleCopyLink}></img>
              <EmailShareButton
                url={this.props.shareUrl}
                data-test-id="email-share"
                onClick={(event) => {
                  event.preventDefault(); 
                  const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&body=${encodeURIComponent(this.props.shareUrl)}`;
                  window.open(gmailUrl, '_blank');
                }}
              >
                <img className="share-popup-icon" src={mailIcon} alt="Share via Email" />
              </EmailShareButton>
            </DialogContentText>
          </DialogContent>
        </ThemeProvider>
      </StyledEngineProvider>
      
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const shareTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const shareWebStyle = {
  shareMainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
