import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import dayjs from "dayjs";

// Customizable Area Start
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  canAddEvents:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  eventDetails:any;
  selectedDateCal:any;
  selectedEventDetails:any;
  addEvents:boolean;
  imageUrl:string;
  avatar: File | null;
  event_title:string;
  event_type:string;
  event_link:string;
  description:string;
  start_time:string;
  end_time:string;
  addEventsEnabled:boolean;
  other_type:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  eventDetailApiId: any;
  addEventsApiCallId:any;
  editEventsApiId:any;
  deleteEventApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      eventDetails:[],
      selectedDateCal:moment().format("YYYY-MM-DD"),
      selectedEventDetails:[],
      addEvents:false,
      imageUrl:"",
      avatar:null,
      event_title:"",
      event_type:"",
      event_link:"",
      description:"",
      start_time:"",
      end_time:"",
      other_type:"",
      addEventsEnabled:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      // this.getServiceProviderDetails({
      //   setApiCallId: "serviceProviderScheduleApiId",
      //   serviceProviderId: this.state.serviceProviderId,
      //   availableDate: this.state.selectedDate,
      //   token,
      // });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === "string") {
          this.showAlert("", responseJson.errors);
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (apiRequestCallId != null) {
        if (apiRequestCallId === this.serviceProviderScheduleApiId) {
          this.setState({
            serviceProviderSchedule:
              responseJson && responseJson.data ? responseJson.data : "",
          });
        }
        if (apiRequestCallId === this.serviceProviderDetailApiId) {
          let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
          msg.addData(
            getName(MessageEnum.CalendarProviderDetailsResponseMessage),
            responseJson
          );
          this.send(msg);
          this.unsubscribeMessages();
          return;
        }
        if (apiRequestCallId === this.eventDetailApiId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          if(responseJson){
            this.setState({eventDetails:responseJson.data}, () => this.handleDateChange(this.state.selectedDateCal))
          }
        }
        if (apiRequestCallId === this.addEventsApiCallId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.setState({addEvents:false, addEventsEnabled:false, other_type:""})
          this.getEventsDetail();
          this.handleDateChange(this.state.selectedDateCal)
        }
        if (apiRequestCallId === this.editEventsApiId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.setState({addEvents:false, addEventsEnabled:false, other_type:""})
          this.getEventsDetail();
          this.handleDateChange(this.state.selectedDateCal)
        }
        if (apiRequestCallId === this.deleteEventApiCallId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          const errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.setState({addEvents:false, addEventsEnabled:false, event_title:'',event_link:"", start_time:"", end_time:"", description:"",imageUrl:"",event_type:""})
          this.getEventsDetail();
        }
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage,
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        // this.getServiceProviderDetails({
        //   ...CalendarProviderDetailsApiMessage,
        //   setApiCallId: "serviceProviderDetailApiId",
        // });
        return;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getEventsDetail();
    this.handleDateChange(this.state.selectedDateCal)
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0,
    });
    // this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate),
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || "";
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null,
    });
    return true;
  }

  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "serviceProviderDetailApiId") {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === "serviceProviderScheduleApiId") {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  handleInputChange = (key: string, value: string ) => {
    this.setState(prevState => ({
          ...prevState,
          [key]: value
  }));
  };

  editAddEvents = () =>{
    this.setState({addEventsEnabled:true});
  }

  addEditEventSubmit = (id:string) => {

    if(this.state.addEventsEnabled && this.state.selectedEventDetails !== null){
      this.editEventSubmit(id);
    }else{
      this.addEventSubmit();
    }
  }

  addEventSubmit = () =>{
    const Token = window.localStorage.getItem("token");

    const header = {
      token: Token
    };
    const formData = new FormData();
    formData.append('event_title', this.state.event_title);
    formData.append('event_type', this.state.event_type);
    formData.append("booking_date", this.state.selectedDateCal);
    formData.append('start_time', this.state.start_time);
    formData.append('end_time', this.state.end_time);
    formData.append('event_link', this.state.event_link);
    formData.append('description', this.state.description);
    
    if (this.state.avatar) {
      formData.append('calendar_banner', this.state.avatar);
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.addEventsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addEventAPiMethod
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod 
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editEventSubmit = (id:string) =>{
    const Token = window.localStorage.getItem("token");

    const header = {
      token: Token
    };
    const formData = new FormData();
    formData.append('event_title', this.state.event_title);
    formData.append('event_type', this.state.event_type);
    formData.append("booking_date", this.state.selectedDateCal);
    formData.append('start_time', this.state.start_time);
    formData.append('end_time', this.state.end_time);
    formData.append('event_link', this.state.event_link);
    formData.append('description', this.state.description);
    
    if (this.state.avatar) {
      formData.append('calendar_banner', this.state.avatar);
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.editEventsApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addEventAPiMethod}/${id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editApiMethodType 
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleImageUpload = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({
          imageUrl: reader.result as string,
          avatar:file
        });
      };
      reader.readAsDataURL(file);
    }
  };
  

  getEventDetailsByDate = (date:any) => {
    const { eventDetails } = this.state; 
    const event = eventDetails?.find((event:any) => event.attributes.booking_date === date);
    return event ? event.attributes : null;
  };

  getEventForDate = (day:any) => {
    const { eventDetails } = this.state;
    return eventDetails?.find((event:any) => event.attributes.booking_date === day.format("YYYY-MM-DD")) || null;
  };

  handleDateChange = (newDate:any) => {
    const formattedDate = dayjs(newDate).format("YYYY-MM-DD");

    const selectedEventDetails = this.getEventDetailsByDate(formattedDate); 

    const eventType = ['Food','Tasting','Trivia','Comedy','Drink','Tournament','Live Music'];

    if(selectedEventDetails !== null){

      this.setState({
        event_title:selectedEventDetails.event_title,
        event_type:selectedEventDetails.event_type,
        start_time:selectedEventDetails.start_time,
        end_time:selectedEventDetails.end_time,
        event_link:selectedEventDetails.event_link,
        description:selectedEventDetails.description,
        imageUrl:selectedEventDetails.calendar_banner[0]?.url
      })

      if(!eventType.includes(selectedEventDetails.event_type)){
        this.setState( {other_type:selectedEventDetails.event_type} )
      }else{
        this.setState( {other_type:""} )
      }

    }else{
      this.setState({
        event_title:"",
        event_type:"",
        start_time:"",
        end_time:"",
        event_link:"",
        description:"",
        imageUrl:""
      })
    }

    this.setState({
      selectedDateCal: formattedDate,
      selectedEventDetails,
      addEvents: (selectedEventDetails !== null && !this.state.addEventsEnabled) ? false : true
    });
  };

  getEventsDetail = () => {

    let business_Id =  this.props.id;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.eventDetailApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getEventDataAPiEndPoint}?id=${business_Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteEvent = (id:string) => {
    const Token = window.localStorage.getItem("token");

    const header = {
      token: Token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteEventApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteEventAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
