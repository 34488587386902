import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import { Box, Button, CircularProgress } from "@material-ui/core";
import "./TermsCondition.css";
import Header from "../../navigationmenu/src/Header.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
const backBtnTandC = require("../assets/back_button.svg");
// Customizable Area End

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController";

export default class TermsConditionsUsers extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return(
      <Box style={{overflowX:"hidden"}}>
        <Header {...this.props} />
        {!this.state.userDescription ? (
          <Box style={styles.spinnerContainer}>
            <CircularProgress color="inherit" size={30} />
          </Box>
        ) : (
          <Box className="mainContainerTerms">
            <Box className="termsAndCondContainer">
              <Button className="btn-container-terms" data-test-id="gotoCustomerBtn" onClick={this.goToCustomerSignUp} >
                <img src={backBtnTandC}></img>
              </Button>
              <div className="font" dangerouslySetInnerHTML={{ __html: this.state.userDescription }} />
            </Box>
          </Box>
        )
        }
        <NavigationMenu {...this.props} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  spinnerContainer: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    height:'100vh'
  },
});
// Customizable Area End
