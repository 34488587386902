import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  shareUrl:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  linkCopied:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      linkCopied:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    handleCopyLink = () => {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(this.props.shareUrl)
          .then(() => {
            this.setState({ linkCopied: true }, () => {
              setTimeout(() => {
                this.setState({ linkCopied: false });
              }, 2000);
            });
          })
          .catch(err => {
            console.error('Failed to copy: ', err);
          });
      } else {
        console.warn('Clipboard API not supported');
      }
    };
  // Customizable Area End
}
