import React from "react";

// Customizable Area Start
import { Container, Box, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  barColors = ["#DA9A22","#C8572C","#C33836","#9C0E2E","#712220","#DA9A22","#C8572C"];
  // Customizable Area End

  render() {
    // Customizable Area Start 

    const data = this.state.checkInDays.map((day, index) => ({
        name: day,
        pv: this.state.checkInCounts[index],
        fill: this.barColors[index],
    }));
  
    const CustomXAxisTick = (props: any) => {
        const { x, y, payload } = props; 
    
        return (
            <>
                <text
                    x={x}
                    y={y + 10} 
                    textAnchor="middle"
                    fill="#18181880" 
                    dominantBaseline="hanging" 
                    fontWeight="bold" 
                    fontSize="1.111vw"
                >
                    {payload.value}
                </text>
                    <text
                        x={x}
                        y={y + 25} 
                        textAnchor="middle"
                        fill="#712220" 
                        dominantBaseline="hanging" 
                        fontWeight="bold" 
                        fontSize="1.111vw"
                    >
                        {this.state.checkInCounts[payload.index]}
                    </text>
            </>
        );
    }; 
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <ResponsiveContainer width="100%" height="65%">
        <BarChart
          data={data}
          margin={{ top: 25,bottom: 35, left: -60, right: 0}}
        >
          <XAxis dataKey="name" axisLine={false} tickLine={false} tick={<CustomXAxisTick />} />
          <YAxis tick={false} axisLine={false} />
          <Bar dataKey="pv" />
        </BarChart>
      </ResponsiveContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
