import React from "react";
import { Link } from "react-scroll";
import { Box, Typography, AppBar, Toolbar, Button } from "@material-ui/core";
import "./LandingPageBlock.css";

import LandingPageController, { Props } from "./LandingPageController";
import {
  groupImg1,
  groupImg2,
  groupImg4,
  groupImg5,
  groupImg6,
  leftBtn,
  rightBtn,
  groupImg8,
  groupImg10,
  groupImg11,
  barHoppingLogoLanding,
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    "fontFamily": `"Museo Sans", sans-serif`,
   }
});
export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { testimonialData, currentTestimonialIndex } = this.state;
    const currentTestimonial = testimonialData[currentTestimonialIndex];
    return (
      <ThemeProvider theme={theme}>
      <Box>
        <Box style={{flexGrow: 1 , overflowX:'hidden'}}>
          <AppBar className="appbar-business-landing">
            <Toolbar className="toolbar-business-landing">
              <div style={{ flexGrow: 1 }}>
                <Button
                  data-test-id="btnLandingHeader1"
                  onClick={() => this.goToLandingPage()}
                >
                  <img
                    alt="bar-hoppin-logo"
                    className="bar-hooping-logo-landing"
                    src={barHoppingLogoLanding}
                  />
                </Button>
              </div>
              <Link
                activeClass="active"
                to="section1"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button
                  className="landing-header-home font2"
                  data-test-id="btnExploreFooter"
                >
                  {" "}
                  Home{" "}
                </Button>
              </Link>
              <Link
                activeClass="active"
                to="section2"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button className="landing-business-list font2">Features</Button>
              </Link>
              <Link
                activeClass="active"
                to="section3"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button
                  className="landing-business-list font2"
                  data-test-id="btnAbout"
                >
                  {" "}
                  Testimonial{" "}
                </Button>
              </Link>
              <Link
                activeClass="active"
                to="section4"
                spy={true}
                smooth={true}
                duration={500}
              >
                <Button
                  className="landing-business-list font2"
                  data-test-id="btnLanding"
                >
                  {" "}
                  Pricing{" "}
                </Button>
              </Link>
              <Button
                className="getStartedButtonLanding buttonsLanding font2"
                data-test-id="btnSignUp"
                onClick={() => this.goToSignup()}
              >
                Get Started
              </Button>
              <Button
                className="loginButtonLanding buttonsLanding font2"
                data-test-id="btnGoToHome"
                onClick={() => this.goToHome()}
              >
                {" "}
                Login{" "}
              </Button>
            </Toolbar>
          </AppBar>
        </Box>
        <Box>
          <div className="landing-container">
            <div id="section1" className="sections">
              <img
                src={groupImg1}
                alt="image-1-landing"
                className="image-1-landing landing-img-width"
              />
            </div>
            <div className="image-label-landing-text">
              <Typography className="image-label-landing">
                Why choose BarHoppin?
              </Typography>
            </div>
            <img
              src={groupImg2}
              alt="image-2-landing"
              className="image-2-landing landing-img-width"
            />
            <div id="section2" className="sections">
              <div className="image-3-landing ">
                <Typography className="image-3-landing-text1">
                  KEY FEATURES
                </Typography>
                <div className="image-3-landing-text2-box">
                  <Typography className="image-3-landing-text2">
                    Key features to bring customers to your establishment
                  </Typography>
                </div>
              </div>
            </div>
            <img
              src={groupImg4}
              alt="image-4-landing"
              className="image-4-landing landing-img-width"
            />
            <div className="image-5-landing">
              <img
                src={groupImg5}
                alt="image-5-landing"
                className="landing-img-width"
              />
            </div>
            <img
              src={groupImg6}
              alt="image-6-landing"
              className="image-6-landing landing-img-width"
            />
            <div id="section3">
              <div className="image-7-landing">
                <Box className="testimonial-wrapper">
                  <Box className="testimonial-content">
                    <Typography className="font-family testimonial-title">TESTIMONIALS</Typography>
                    <Typography className="font-family testimonial-heading">{currentTestimonial?.header}</Typography>
                    <Typography className="font-family testimonial-desc" dangerouslySetInnerHTML={{ __html: currentTestimonial?.description }}></Typography>
                    <Box className="auth-btn-wrapper">
                      <Typography className="font-family testimonial-auth">{currentTestimonial?.author_name}</Typography>
                      <Box className="testimonial-btn-container">
                        <Button className="testimonial-btn" onClick={this.handlePrevTestomonial}><img className="testimonial-btn-img" src={leftBtn}></img></Button>
                        <Button className="testimonial-btn" onClick={this.handleNextTestimonial}><img className="testimonial-btn-img" src={rightBtn}></img></Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
            <div>
              <img
                src={groupImg8}
                alt="image-8-landing"
                className="image-8-landing"
              />
            </div>
            <div className="image-9-landing">
              <Typography className="image-3-landing-text1">
                COMING SOON
              </Typography>
              <div className="image-3-landing-text2-box">
                <Typography className="image-3-landing-text2">
                  Next level features on the way
                </Typography>
              </div>
            </div>
            <img
              src={groupImg10}
              alt="image-10-landing"
              className="image-10-landing landing-img-width"
            />
            <div id="section4" className="sections">
              <div className="image-11-landing">
                <img
                  src={groupImg11}
                  alt="image-11-landing"
                  className="image-11-landing-img"
                />
              </div>
            </div>
          </div>
          <Toolbar className="toolbar-business-landing">
            <div style={{ flexGrow: 1 }}>
              <Button
                data-test-id="btnLandingHeader2"
                onClick={() => this.goToLandingPage()}
              >
                <img
                  src={barHoppingLogoLanding}
                  alt="bar-hoppin-logo"
                  className="bar-hooping-logo-landing"
                />
              </Button>
            </div>
            <Typography
              variant="body2"
              className="typographyStyleFooterLanding font2"
            >
              <Typography
                variant="button"
                className="termsCondColorLanding font2"
                data-test-id="btnTermsLanding"
                onClick={() => this.goToTermsAndCondition()}
              >
                Terms and Conditions
              </Typography>
              &nbsp; &bull; &nbsp; info@GoBarHoppin.com &nbsp; &bull; &nbsp;151
              Portage Trail #2, Cuyahoga Falls, Ohio 44221
            </Typography>
            <Button
              className="getStartedButtonLanding buttonsLanding font2"
              data-test-id="btnLogin"
              onClick={() => this.goToSignup()}
            >
              Get Started
            </Button>
          </Toolbar>
        </Box>
      </Box>
      </ThemeProvider>
    );
  }
}
